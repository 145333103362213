<template>
  <div ref="target" class="shrink-0 min-h-16">
    <Transition v-if="animate" name="line" appear>
      <div
        v-if="showLine"
        class="animated-line mx-auto h-full min-h-16 w-px"
        :style="{ '--delay': delayInMs }"
      />
    </Transition>
    <div
      v-else
      ref="target"
      class="mx-auto h-full min-h-16 w-px bg-current"
    />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    animate?: boolean
    active?: MaybeRef<boolean>
    delay?: number | string
  }>(),
  {
    animate: true,
    active: true,
    delay: '800ms',
  }
)

const target = ref<HTMLElement>()

const visible = useElementVisibility(target)

const showLine = computed(() => toValue(props.active) && visible.value)

const delayInMs = computed(() => {
  if (typeof props.delay === 'number') {
    return `${props.delay}ms`
  } else {
    return props.delay
  }
})
</script>

<style scoped>
.animated-line {
  background: linear-gradient(
      transparent 0%,
      currentColor 0%,
      currentColor 100%,
      transparent 100%
    )
    no-repeat;
  background-size: 100%;
  background-position-y: 0;
  transition: background-size 0.5s 400ms ease-in-out;
}

.line-enter-active,
.line-leave-active {
  background-size: 100% 100%;
}

.line-enter-from,
.line-leave-to {
  background-size: 100% 0;
}
</style>
